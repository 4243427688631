import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import logo from '../assets/img/d2i-technology-logo-with-white-bg.png'

const currentYear = new Date().getFullYear()

const Footer = () => (
    <footer className="bg-dark text-white pt-5 pb-5">
      {/* <div className="container d-md-flex py-4">
      <div className="col-12 text-center py-4">
        <div className="copyright text-center">
          <p>
            <a href="tel:+918265963621">
              <i className="icofont-mobile-phone" aria-hidden="true"></i>
              +91 82659 63621
            </a>
            <strong> | </strong>
            <a href="mailto:info@d2itechnology.com">
              <i className="icofont-envelope" aria-hidden="true"></i>
              {" "}info@d2itechnology.com
            </a>
          </p>
        </div>
        <div className="copyright text-center">
          <p>
            &copy;{currentYear}{" "}
            <strong>
              <span>D2i Technology</span>
            </strong>
            . All Rights Reserved.
          </p>
        </div>
        <div className="social-links text-center">
          <ul className="list-unstyled inline-list">
            <li className="list-inline-item mb-2">
              <a
                href="https://twitter.com/d2itechnology"
                target="_blank"
                title="Twitter"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="32"
                  viewBox="0 0 50 50"
                  fill="white"
                  aria-hidden="true"
                  focusable="false"
                  style={{ marginTop: "-5px" }}
                >
                  <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path>
                </svg>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.facebook.com/d2itechnology"
                target="_blank"
                title="Facebook"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.instagram.com/d2itechnology/"
                target="_blank"
                title="Instagram"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.linkedin.com/company/d2itechnology"
                target="_blank"
                title="LinkedIn"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-linkedin" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.youtube.com/channel/UCt81Dry8H_uc-35IDIN6biA"
                target="_blank"
                title="YouTube"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-youtube" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.reddit.com/user/d2itechnology"
                target="_blank"
                title="Reddit"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-reddit" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://in.pinterest.com/d2itechnology/"
                target="_blank"
                title="Pinterest"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-pinterest-alt" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.quora.com/profile/D2itechnology"
                target="_blank"
                title="Quora"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-quora" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="mb-3">
              <AnchorLink gatsbyLinkProps={{activeClassName: "active"}} className="logo"  to="/">
                <img src={logo} alt="D2i Technology Logo" width={90} height={80} />
                <span className="d-sm-inline mt-1 text-white font-weight-bold ml-1" aria-hidden="true">D2i Technology</span>
              </AnchorLink>
            </div>
            <h2 className="mb-3 h5">About D2i Technology</h2>
            <p className="text-light">
              D2i Technology, a top software development company, offers expert services in Development, DevOps, and Accessibility Testing to clients in the USA. Our talented team delivers innovative solutions to optimize development, deployment, and testing for your business success.
            </p>
          </div>
          <div className="col-md-2 mb-2">
            <h2 className="mb-3 h5">Quick Links</h2>
            <ul className="list-unstyled">
              <li className="mb-2"><a href="/#about" className="text-light">About Us</a></li>
              <li className="mb-2"><a href="/#services" className="text-light">Services</a></li>
              <li className="mb-2"><a href="/#portfolio" className="text-light">Our Products</a></li>
              <li className="mb-2"><a href="/careers" className="text-light">Careers</a></li>
              <li className="mb-2"><a href="/blogs" className="text-light">Blogs</a></li>
              <li className="mb-2"><a href="/sitemap" className="text-light">Sitemap</a></li>
              <li className="mb-2"><a href="/color-contrast-analyzer" className="text-light">CCA</a></li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h2 className="mb-3 h5">Contact Us</h2>
            <ul className="list-unstyled contact-us-details">
              <li className="mb-2">
                <strong className="mb-2">Call Us:</strong><br />
                <a href="tel:+918265963621" className="text-light">
                  <i className="icofont-mobile-phone mr-2"></i>
                  +91 82659 63621
                </a>
              </li>
              <li className="mb-2">
                <strong>Email Us:</strong><br />
                <a href="mailto:info@d2itechnology.com" className="text-light">
                  <i className="icofont-envelope mr-2"></i>
                  info@d2itechnology.com
                </a>
              </li>
              <li>
                <strong>Follow Us:</strong><br />
                <div>
                  <ul className="list-unstyled inline-list">
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://twitter.com/d2itechnology"
                        target="_blank"
                        title="Twitter"
                        rel="noopener noreferrer"
                        className="pt-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 50 50"
                          fill="white"
                          aria-hidden="true"
                          focusable="false"
                          style={{ marginTop: "-9px" }}
                        >
                          <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path>
                        </svg>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://www.facebook.com/d2itechnology"
                        target="_blank"
                        title="Facebook"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://www.instagram.com/d2itechnology/"
                        target="_blank"
                        title="Instagram"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://www.linkedin.com/company/d2itechnology"
                        target="_blank"
                        title="LinkedIn"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://www.youtube.com/channel/UCt81Dry8H_uc-35IDIN6biA"
                        target="_blank"
                        title="YouTube"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://www.reddit.com/user/d2itechnology"
                        target="_blank"
                        title="Reddit"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-reddit" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://in.pinterest.com/d2itechnology/"
                        target="_blank"
                        title="Pinterest"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-pinterest-alt" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mb-2">
                      <a
                        href="https://www.quora.com/profile/D2itechnology"
                        target="_blank"
                        title="Quora"
                        rel="noopener noreferrer"
                        className="text-white mr-2 pt-2"
                      >
                        <i className="bx bxl-quora pt-2" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Horizontal Line */}
        <hr className="border-secondary mt-0" aria-hidden="true" />

        {/* Second Row - Copyright and Policy Links */}
        <div className="row">
          <div className="col-md-6 text-center text-md-left mb-2 mb-md-0">
            <a href="/accessibility-statement" target="_blank" rel="noopener noreferrer" className="text-light mr-3">Accessibility Statement</a>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-light">Privacy Policy</a>
          </div>
          <div className="col-md-6 text-center text-md-right">
            <p className="mb-0">
              &copy; {currentYear} <strong>D2i Technology</strong>. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
)

export default Footer
